import * as React from "react"
import appInsights from '../../../AppInsights';
import Home from "../../route/home_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Home props={props} />
      </React.Fragment>
  )
}

export default IndexPage
